import { FetchRequestGET, FetchRequestPATCH } from "../api"


export async function OrganisationShow() {
	return FetchRequestGET(`/organisations/me`)
}

export async function OrganisationUpdate(body: any) {
	return FetchRequestPATCH(
		`/organisations/me`,
		body
	)
}
