import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'

export default function TemplateTable({ data, columns, openCreateModal, openMaintainModal, name, disableCreate, UnderComponent }) {

	const [sorting, setSorting] = useState([])
	const [filtering, setFiltering] = useState('')

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			sorting: sorting,
			globalFilter: filtering,
		},
		onSortingChange: setSorting,
		onGlobalFilterChange: setFiltering,
	})

	return (
		<>
			<div className='w3-container'>
				<div className="container-fluid mt-4 max-w-screen-2xl">
					<div className="flex-col">
						<div className="px-4 sm:px-6 lg:px-8">
							<div className="sm:flex sm:items-end">
								<div className="sm:flex-auto">
									<label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
										Search
									</label>
									<input
										id="search"
										name="search"
										type='text'
										value={filtering}
										onChange={e => setFiltering(e.target.value)}
										className="block w-full max-w-md rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
									/>
								</div>
								<div hidden={disableCreate} className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
									<button
										id="createButton"
										onClick={openCreateModal}
										type="button"
										className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
									>
										Create {name}
									</button>
								</div>
							</div>
							<UnderComponent />
							<div className="mt-8 flow-root">
								<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
										<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
											<table className="min-w-full divide-y divide-gray-300">
												<thead className="bg-gray-50">
													{table.getHeaderGroups().map(headerGroup => (
														<tr key={headerGroup.id}>
															{headerGroup.headers.map(header => (
																<th
																	key={header.id}
																	onClick={header.column.getToggleSortingHandler()}
																	className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
																>
																	{header.isPlaceholder ? null : (
																		<div>
																			{flexRender(
																				header.column.columnDef.header,
																				header.getContext()
																			)}
																			{
																				{ asc: '🔼', desc: '🔽' }[
																				header.column.getIsSorted() ?? null
																				]
																			}
																		</div>
																	)}
																</th>
															))}
														</tr>
													))}
												</thead>

												<tbody className="divide-y divide-gray-200 bg-white">
													{table.getRowModel().rows.map(row => {
														return (
															<tr
																key={row.id}
																className="hover:bg-blue-600 text-gray-500 hover:text-white hover:cursor-pointer"
																onClick={() => openMaintainModal(row.original.id)}
															>
																{row.getVisibleCells().map(cell => (
																	<td key={cell.id} className="whitespace-nowrap px-3 py-4 text-sm">
																		{flexRender(cell.column.columnDef.cell, cell.getContext())}
																	</td>
																))}
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div className="grid grid-cols-4 divide-x-2 w-full max-w-lg mt-2">
								<button
									onClick={() => table.setPageIndex(0)}
									className="flex w-full justify-center bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 cursor-pointer rounded-s-lg"
								>
									First page
								</button>
								<button
									disabled={!table.getCanPreviousPage()}
									onClick={() => table.previousPage()}
									className="flex w-full justify-center bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 cursor-pointer"
								>
									Previous page
								</button>
								<button
									disabled={!table.getCanNextPage()}
									onClick={() => table.nextPage()}
									className="flex w-full justify-center bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 cursor-pointer"
								>
									Next page
								</button>
								<button
									onClick={() => table.setPageIndex(table.getPageCount() - 1)}
									className="flex w-full justify-center bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 cursor-pointer rounded-e-lg"
								>
									Last page
								</button>
							</div>
						</div>
					</div >
				</div >
			</div >
		</>

	)
}