import { useState } from "react"
import {
	useNavigate,
} from "react-router-dom";
import { RegisterUser } from "../../lib/backend/auth";
import { setToken } from "../../lib/token";

export default function RegisterPage() {
	const [formDataEmail, setFormDataEmail] = useState("")
	const [formDataPassword, setFormDataPassword] = useState("")
	const [formDataPasswordConfirm, setFormDataPasswordConfirm] = useState("")
	const [formDataFirstName, setFormDataFirstName] = useState("")
	const [formDataLastName, setFormDataLastName] = useState("")
	const [formDataError, setFormDataError] = useState(false)
	const [formDataErrorText, setFormDataErrorText] = useState("")

	const navigate = useNavigate();

	async function formSubmit(event: any) {
		event.preventDefault()

		let result = false

		if (formDataPassword !== formDataPasswordConfirm) {
			setFormDataError(true)
			setFormDataErrorText("Password and Password Confirm must be a match")
		} else if (formDataPassword.length < 6) {
			setFormDataError(true)
			setFormDataErrorText("Password must be 6 characters or more")
		} else {
			result = true
			setFormDataError(false)
			setFormDataErrorText("")
		}

		if (result) {
			const response = await RegisterUser(formDataEmail, formDataPassword, formDataPasswordConfirm, formDataFirstName, formDataLastName)
			if (response.ok) {
				const body = await response.json()
				setToken(body.token)
				navigate("/home/dashboard")
				// })
			} else {
				const body = await response.json()
				if (body.email && body.email[0] && body.email[0] === "has already been taken") {
					setFormDataError(true)
					setFormDataErrorText("This email address has already been used.")
				} else {
					setFormDataError(true)
					setFormDataErrorText("Something has gone wrong. Please try again.")
				}
			}
		}
	}

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<img
						className="mx-auto h-10 w-auto"
						src="/temp-trackr-logo-blue.svg"
						alt="Temp Trackr"
					/>
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						Create an account
					</h2>
				</div>

				<div hidden={!formDataError} className="mx-auto text-red-500 mt-8 text-xl">
					{formDataErrorText}
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={formSubmit}>
						<div>
							<label htmlFor="FirstName" className="block text-sm font-medium leading-6 text-gray-900">
								First Name
							</label>
							<div className="mt-2">
								<input
									id="FirstName"
									name="FirstName"
									type="text"
									autoComplete="FirstName"
									required
									onChange={(e) => setFormDataFirstName(e.target.value)}
									value={formDataFirstName}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
						<div>
							<label htmlFor="LastName" className="block text-sm font-medium leading-6 text-gray-900">
								Last Name
							</label>
							<div className="mt-2">
								<input
									id="LastName"
									name="LastName"
									type="text"
									autoComplete="LastName"
									required
									onChange={(e) => setFormDataLastName(e.target.value)}
									value={formDataLastName}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
						<div>
							<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
								Email address
							</label>
							<div className="mt-2">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									onChange={(e) => setFormDataEmail(e.target.value)}
									value={formDataEmail}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
									Password
								</label>
							</div>
							<div className="mt-2">
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									onChange={(e) => setFormDataPassword(e.target.value)}
									value={formDataPassword}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="passwordConfirm" className="block text-sm font-medium leading-6 text-gray-900">
									Password Confirm
								</label>
							</div>
							<div className="mt-2">
								<input
									id="passwordConfirm"
									name="passwordConfirm"
									type="password"
									autoComplete="current-password-confirm"
									required
									onChange={(e) => {
										setFormDataPasswordConfirm(e.target.value)
									}}
									value={formDataPasswordConfirm}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Sign up
							</button>
						</div>
					</form>

				</div>
			</div>
		</>
	)
}
