import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function TempIndex(type: string) {
	return FetchRequestGET(`/temps?type=${type}`)
}

export async function TempShow(id: number) {
	return FetchRequestGET(`/temps/${id}`)
}

export async function TempUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/temps/${id}`,
		body
	)
}

export async function TempCreate(body: any) {
	return FetchRequestPOST(
		`/temps`,
		body
	)
}

export async function TempDelete(id: number) {
	return FetchRequestDELETE(
		`/temps/${id}`)
}

