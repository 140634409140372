import { useState } from "react"
import { url } from "../../lib/environment"
import { PasswordReset } from "../../lib/backend/auth"

export default function LoginPage() {
	const [formDataEmail, setFormDataEmail] = useState("")
	const [formDataError, setFormDataError] = useState(false)
	const [formDataSuccess, setFormDataSuccess] = useState(false)

	async function formSubmit(event: any) {
		event.preventDefault()

		setFormDataError(false)

		const response = await PasswordReset(formDataEmail)
		if (response.ok) {
			setFormDataSuccess(true)
			setFormDataError(false)
		} else {
			setFormDataSuccess(false)
			setFormDataError(true)
		}

	}

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<img
						className="mx-auto h-10 w-auto"
						src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
						alt="Temp Trackr"
					/>
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						Forgot password
					</h2>
				</div>

				<div hidden={!formDataError} className="mx-auto text-red-500 mt-8 text-xl">
					Invalid email
				</div>

				<div hidden={!formDataSuccess} className="mx-auto text-green-500 mt-8 text-xl">
					An email has been sent with further instructions
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={formSubmit}>
						<div>
							<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
								Email address
							</label>
							<div className="mt-2">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									onChange={(e) => setFormDataEmail(e.target.value)}
									value={formDataEmail}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Change password
							</button>
						</div>
					</form>

				</div>
			</div>
		</>
	)
}
