import { FetchRequestPOST, FetchRequestGET, FetchRequestDELETE, FetchRequestPATCH } from "../api"

export async function RegisterUser(email: string, password: string, password_confirmation: string, first_name: string, last_name: string) {
	return FetchRequestPOST(
		'/sign_up',
		{ email, password, password_confirmation, first_name, last_name }
	)
}

export async function LoginUser(email: string, password: string) {
	return FetchRequestPOST(
		'/sign_in',
		{ email, password }
	)
}

export async function LogoutUser() {
	return FetchRequestDELETE('/sessions/destroy')
}

export async function GetSession() {
	return FetchRequestGET(
		"/sessions/my_session"
	)
}

export async function UpdatePassword(password: string, password_confirmation: string, sid: string) {
	return FetchRequestPATCH(
		"/identity/password_reset",
		{ password: password, password_confirmation: password_confirmation, sid: sid }
	)
}

export async function VerifyEmail(sid: string) {
	return FetchRequestGET(
		`/identity/email_verification?sid=${sid}`
	)
}

export async function PasswordReset(email: string) {
	return FetchRequestPOST(
		"/identity/password_reset",
		{ email: email }
	)
}


