import { FetchRequestDELETE, FetchRequestGET, FetchRequestPOST, FetchRequestPOSTWithHeaders } from "../api";

export async function FileIndex() {
	return FetchRequestGET(`/files`)
}

export async function FileIndexUser(user: number) {
	return FetchRequestGET(`/files?user=${user}`)
}

export async function FileShow(tempKey: string, documentNumber: string) {
	return FetchRequestGET(`/files/show?key=${tempKey}&document_number=${documentNumber}`)
}

export async function FileCreate(userID: number, file: any, tempKey: string, documentNumber: string) {
	return FetchRequestPOSTWithHeaders(
		`/files/create?user=${userID}&key=${tempKey}&filename=${file.name}&document_number=${documentNumber}`,
		{
			'content-type': file.type,
			'content-length': `${file.size}`, // 👈 Headers need to be a string
		},
		file
	)
}

export async function FileDelete(id: number) {
	return FetchRequestDELETE(
		`/files/${id}`)
}






