import { FetchRequestPOST } from "../api"

export async function RegisterUser(body: any) {
	return FetchRequestPOST(
		'/sign_up',
		body
	)
}

export async function RegisterTemp(body: any) {
	return FetchRequestPOST(
		'/temps',
		body
	)
}

