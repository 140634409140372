import { useEffect, useState } from "react"
import {
	useNavigate, useParams,
} from "react-router-dom";
import { RegisterTemp } from "../../lib/backend/authPublic";
import { setToken } from "../../lib/token";
import { PublicTempTypeIndex } from "../../lib/backend/publicTempTypes";

export default function PublicOrgSignupPage() {

	const [formDataEmail, setFormDataEmail] = useState("")
	const [formDataPassword, setFormDataPassword] = useState("")
	const [formDataPasswordConfirm, setFormDataPasswordConfirm] = useState("")
	//
	const [formDataFirstName, setFormDataFirstName] = useState("")
	const [formDataLastName, setFormDataLastName] = useState("")
	//
	const [formDataTempType, setFormDataTempType] = useState(0)
	const [formDataAddress, setFormDataAddress] = useState("")
	const [formDataCity, setFormDataCity] = useState("")
	const [formDataState, setFormDataState] = useState("")
	const [formDataZip, setFormDataZip] = useState("")
	const [formDataPhoneNumber, setFormDataPhoneNumber] = useState("")

	const [formDataError, setFormDataError] = useState(false)
	const [formDataErrorText, setFormDataErrorText] = useState("")

	const [loadingInitial, setLoadingInitial] = useState(true)

	const [tempTypes, setTempTypes] = useState<[{ id: number, name: string }] | []>([])

	const navigate = useNavigate();

	const { org_name } = useParams();

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		if (org_name) {
			const response = await PublicTempTypeIndex(org_name)
			const body = await response.json()
			setTempTypes(body)
			setLoadingInitial(false)
		}
	}

	async function formSubmit(event: any) {
		event.preventDefault()

		let result = false

		if (formDataPassword !== formDataPasswordConfirm) {
			setFormDataError(true)
			setFormDataErrorText("Password and Password Confirm must be a match")
		} else if (formDataPassword.length < 6) {
			setFormDataError(true)
			setFormDataErrorText("Password must be 6 characters or more")
		} else {
			result = true
			setFormDataError(false)
			setFormDataErrorText("")
		}

		if (result) {
			const response = await RegisterTemp({
				user: {
					email: formDataEmail,
					password: formDataPassword,
					password_confirmation: formDataPasswordConfirm,
					//
					first_name: formDataFirstName,
					last_name: formDataLastName,
					//
					street_address: formDataAddress,
					city: formDataCity,
					state_province: formDataState,
					zip_postal_code: formDataZip,
					phone_number: formDataPhoneNumber,
					link: org_name,
					temp_type_id: formDataTempType
				}
			})
			if (response.ok) {
				const body = await response.json()
				setToken(body.token)
				navigate("/home/dashboard")
			} else {
				const body = await response.json()
				if (body.email && body.email[0] && body.email[0] === "has already been taken") {
					setFormDataError(true)
					setFormDataErrorText("This email address has already been used.")
				} else {
					setFormDataError(true)
					setFormDataErrorText("Something has gone wrong. Please try again.")
				}
			}
		}
	}

	return (
		<>
			<div className="flex min-h-full flex-1 flex-col px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<img
						className="mx-auto h-10 w-auto"
						src="/temp-trackr-logo-blue.svg"
						alt="Temp Trackr"
					/>
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
						Create an account
					</h2>
				</div>

				<div hidden={!formDataError} className="mx-auto text-red-500 mt-8 text-xl">
					{formDataErrorText}
				</div>

				<div className="w-full mt-10" hidden={!loadingInitial}>
					<div className="flex w-full justify-center">
						<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
							<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
						</svg>
					</div>
				</div>

				<div hidden={loadingInitial} className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={formSubmit}>

						<div className="border-b border-gray-900/10 pb-12">
							<div className="flex items-center justify-between">
								<label htmlFor="TempType" className="block text-sm font-medium leading-6 text-gray-900">
									Temporary Type
								</label>
							</div>
							<div className="mt-2">
								<select
									id="TempType"
									name="TempType"
									autoComplete="temp-type"
									required
									onChange={(e) => {
										setFormDataTempType(parseInt(e.target.value))
									}}
									value={formDataTempType}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								>
									<option value={0}>Select a Temporary Type</option>
									{tempTypes.map(item =>
										<option key={item.id} value={item.id}>{item.name}</option>
									)}
								</select>
							</div>
						</div>

						<div>
							<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
								Email address
							</label>
							<div className="mt-2">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									onChange={(e) => setFormDataEmail(e.target.value)}
									value={formDataEmail}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
									Password
								</label>
							</div>
							<div className="mt-2">
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									onChange={(e) => setFormDataPassword(e.target.value)}
									value={formDataPassword}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="border-b border-gray-900/10 pb-12">
							<div className="flex items-center justify-between">
								<label htmlFor="passwordConfirm" className="block text-sm font-medium leading-6 text-gray-900">
									Password Confirm
								</label>
							</div>
							<div className="mt-2">
								<input
									id="passwordConfirm"
									name="passwordConfirm"
									type="password"
									autoComplete="current-password-confirm"
									required
									onChange={(e) => {
										setFormDataPasswordConfirm(e.target.value)
									}}
									value={formDataPasswordConfirm}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
									First name
								</label>
							</div>
							<div className="mt-2">
								<input
									id="first-name"
									name="first-name"
									type="text"
									autoComplete="first-name"
									required
									onChange={(e) => setFormDataFirstName(e.target.value)}
									value={formDataFirstName}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="border-b border-gray-900/10 pb-12">
							<div className="flex items-center justify-between">
								<label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
									Last name
								</label>
							</div>
							<div className="mt-2">
								<input
									id="last-name"
									name="last-name"
									type="text"
									autoComplete="last-name"
									required
									onChange={(e) => {
										setFormDataLastName(e.target.value)
									}}
									value={formDataLastName}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
								Street address
							</label>
							<div className="mt-2">
								<input
									type="text"
									name="street-address"
									id="street-address"
									autoComplete="street-address"
									onChange={(e) => setFormDataAddress(e.target.value)}
									value={formDataAddress}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-2 sm:col-start-1">
							<label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
								City
							</label>
							<div className="mt-2">
								<input
									type="text"
									name="city"
									id="city"
									autoComplete="address-level2"
									onChange={(e) => setFormDataCity(e.target.value)}
									value={formDataCity}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-2">
							<label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
								State / Province
							</label>
							<div className="mt-2">
								<input
									type="text"
									name="region"
									id="region"
									autoComplete="address-level1"
									onChange={(e) => setFormDataState(e.target.value)}
									value={formDataState}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="border-b border-gray-900/10 pb-12">
							<label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
								ZIP / Postal code
							</label>
							<div className="mt-2">
								<input
									type="text"
									name="postal-code"
									id="postal-code"
									autoComplete="postal-code"
									onChange={(e) => setFormDataZip(e.target.value)}
									value={formDataZip}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div className="sm:col-span-2">
							<label htmlFor="phone-number" className="block text-sm font-medium leading-6 text-gray-900">
								Phone number
							</label>
							<div className="mt-2">
								<input
									type="text"
									name="phone-number"
									id="phone-number"
									autoComplete="phone-number"
									onChange={(e) => setFormDataPhoneNumber(e.target.value)}
									value={formDataPhoneNumber}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Sign up
							</button>
						</div>
					</form>

				</div>
			</div>
		</>
	)
}
