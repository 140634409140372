import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function UserIndex() {
	return FetchRequestGET("/users")
}

export async function UserGetMe() {
	return FetchRequestGET("/users/me")
}

export async function UserUpdateMe(body: any) {
	return FetchRequestPOST(
		"/users/me",
		body
	)
}

export async function UserShow(id: number) {
	return FetchRequestGET(`/users/${id}`)
}

export async function UserUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/users/${id}`,
		body
	)
}

export async function UserCreate(body: any) {
	return FetchRequestPOST(
		`/users`,
		body
	)
}

export async function UserDelete(id: number) {
	return FetchRequestDELETE(
		`/users/${id}`)
}

