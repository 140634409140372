export function url() {
	if (process.env.NODE_ENV === "development") {
		return "http://localhost:3333"
	} else {
		return "https://api.temp-trackr.com"
	}
}

export function pageUrl() {
	if (process.env.NODE_ENV === "development") {
		return "http://localhost:3333"
	} else {
		return "https://temp-trackr.com"
	}
}