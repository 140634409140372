import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
	createBrowserRouter,
	Navigate,
	RouterProvider,
} from "react-router-dom";

import LandingPage from './pages/LandingPage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ChangePasswordPage from './pages/auth/ChangePasswordPage';
import DashboardPage from './pages/home/DashboardPage';
import HomePage from './pages/home/HomePage';
import OfficesPage from './pages/home/OfficesPage';
import AccountPage from './pages/home/AccountPage';
import LogoutPage from './pages/auth/LogoutPage';
import UserPage from './pages/home/UserPage';
import EmailVerifyPage from './pages/auth/EmailVerifyPage';
import OrganisationPage from './pages/home/OrganisationPage';
import TempTypesPage from './pages/home/settings/TempTypes';
import PublicOrgSignupPage from './pages/auth/PublicOrgSignupPage';
import DocumentTypesPage from './pages/home/settings/DocumentTypes';
import TempsPageAll from './pages/home/TempsPageAll';
import TempsPageApproved from './pages/home/TempsPageApproved';
import TempsPageEntered from './pages/home/TempsPageEntered';
import TempsPageRejected from './pages/home/TempsPageRejected';
import TempsPageBlacklisted from './pages/home/TempsPageBlacklisted';
// import ClientsPage from './pages/home/ClientsPage';
// import TripsPage from './pages/home/TripsPage';
// import ReportsPage from './pages/home/ReportsPage';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
	{
		path: "/",
		element: <LandingPage />,
	},
	{
		path: "/identity/password_reset/edit",
		element: <ChangePasswordPage />
	},
	{
		path: "/identity/email_verification",
		element: <EmailVerifyPage />
	},
	{
		path: "/login",
		element: <LoginPage />
	},
	{
		path: "/register",
		element: <RegisterPage />
	},
	{
		path: "/forgot-password",
		element: <ForgotPasswordPage />
	},
	{
		path: "/change-password",
		element: <ChangePasswordPage />
	},
	{
		path: "/logout",
		element: <LogoutPage />
	},
	{
		path: "/signup/:org_name",
		element: <PublicOrgSignupPage />
	},
	{
		path: "/home",
		element: <HomePage />,
		children: [
			{
				path: "/home/dashboard",
				element: <DashboardPage />
			},
			{
				path: "/home/offices",
				element: <OfficesPage />
			},
			{
				path: "/home/organisation",
				element: <OrganisationPage />
			},
			{
				path: "/home/account",
				element: <AccountPage />
			},
			{
				path: "/home/temps",
				element: <TempsPageAll />
			},
			{
				path: "/home/temps-approved",
				element: <TempsPageApproved />
			},
			{
				path: "/home/temps-entered",
				element: <TempsPageEntered />
			},
			{
				path: "/home/temps-rejected",
				element: <TempsPageRejected />
			},
			{
				path: "/home/temps-blacklisted",
				element: <TempsPageBlacklisted />
			},
			{
				path: "/home/users",
				element: <UserPage />
			},
			// {
			// 	path: "/home/trips",
			// 	element: <TripsPage />
			// },
			// {
			// 	path: "/home/reports",
			// 	element: <ReportsPage />
			// },
		]
	},
	{
		path: "/home/settings",
		element: <HomePage />,
		children: [
			{
				path: "/home/settings/temp-types",
				element: <TempTypesPage />
			},
			{
				path: "/home/settings/document-types",
				element: <DocumentTypesPage />
			}
		]
	},
]);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
