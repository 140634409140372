import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TemplateTable from "../../../components/TemplateTable"
import { XMarkIcon } from '@heroicons/react/24/outline'
import { TempTypeCreate, TempTypeDelete, TempTypeIndex, TempTypeShow, TempTypeUpdate } from '../../../lib/backend/tempTypes'
import { DocumentTypeIndex } from '../../../lib/backend/documentTypes'
import { TempDocumentsCreate, TempDocumentsDelete, TempDocumentsShow } from '../../../lib/backend/tempDocuments'

const columns = [
	{
		header: "Name",
		accessorKey: "name",
	},
]

export default function TempTypesPage() {
	const [loadingData, setLoadingData] = useState(true)
	const [tableData, setTableData] = useState<any>([])
	const [tableDocumentData, setTableDocumentData] = useState<any>([])
	const [tableDocumentSelectedData, setTableDocumentSelectedData] = useState<any>([])
	const [tableDocumentSelectedOriginalData, setTableDocumentSelectedOriginalData] = useState<any>([])

	const [createModalOpen, setCreateModalOpen] = useState(false)
	const [createModalLoading, setcreateModalLoading] = useState(false)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [editModalLoading, setEditModalLoading] = useState(false)
	const [showCreateError, setShowCreateError] = useState(false)
	const [showMaintainError, setShowMaintainError] = useState(false)

	const [validName, setValidName] = useState(false)

	const [formDataID, setFormDataID] = useState(0)
	const [formDataName, setFormDataName] = useState("")
	const [formDataNotes, setFormDataNotes] = useState("")

	const cancelButtonRef = useRef(null)

	if (loadingData === true) {
		getData()
	}

	async function getData() {
		const response = await TempTypeIndex()
		const body = await response.json()
		setLoadingData(false)
		setTableData(body);
	}

	async function openCreateModal() {
		//
		setcreateModalLoading(true)
		setCreateModalOpen(true)
		setShowCreateError(false)
		//
		const response = await DocumentTypeIndex()
		const body = await response.json()
		setcreateModalLoading(false)
		setTableDocumentData(body)
		setTableDocumentSelectedOriginalData([])
		setTableDocumentSelectedData([])
		//
		setFormDataName("")
		setFormDataNotes("")
		//
	}

	async function submitCreateForm(event: any) {
		//
		event.preventDefault();
		//
		setcreateModalLoading(true)
		setShowCreateError(false)
		//
		const response = await TempTypeCreate({
			temp_type: {
				name: formDataName,
				notes: formDataNotes
			}
		})
		if (response.ok) {
			const body = await response.json()
			//
			for (const doc in tableDocumentSelectedData) {
				const responseTempDocCreate = await TempDocumentsCreate({
					temp_document: {
						temp_type_id: body.id,
						document_type_id: tableDocumentSelectedData[doc].document_type_id
					}
				})
				if (!responseTempDocCreate) {
					alert("Error! Please advise your provider!")
				}
			}
			//
			const tableDataUpdated: any = JSON.parse(JSON.stringify(tableData))
			tableDataUpdated.push({
				id: body.id,
				name: body.name,
			})
			setTableData(tableDataUpdated)
			setCreateModalOpen(false)
			//
		}
		//
	}

	async function openMaintainModal(id: number) {
		//
		setEditModalLoading(true)
		setEditModalOpen(true)
		//
		setFormDataID(id)
		//
		const response = await TempTypeShow(id)
		const body = await response.json()
		setFormDataName(body.name)
		setFormDataNotes(body.notes || "")
		formDataValidation()
		//
		const responseDocType = await DocumentTypeIndex()
		const bodyDocType = await responseDocType.json()
		setTableDocumentData(bodyDocType)
		//
		const responseTempDocShow = await TempDocumentsShow(id)
		const bodyTempDocShow = await responseTempDocShow.json()
		setTableDocumentSelectedData(bodyTempDocShow)
		setTableDocumentSelectedOriginalData(bodyTempDocShow)
		//
		setEditModalLoading(false)
		//
	}

	async function submitMaintainForm(event: any) {
		//
		event.preventDefault();
		//
		setEditModalLoading(true)
		setShowMaintainError(false)
		//
		const response = await TempTypeUpdate(formDataID, {
			name: formDataName,
			notes: formDataNotes,
		})
		if (response.ok) {
			const body = await response.json()
			const tableDataUpdated: any = tableData.map((tableDataRow: any) => {
				if (tableDataRow.id === formDataID) {
					tableDataRow = {
						id: formDataID,
						name: body.name,
					}
				}
				return tableDataRow
			})
			setTableData(tableDataUpdated)
			//
			for (const doc in tableDocumentSelectedData) {
				// Only create the NEW ones
				if (tableDocumentSelectedOriginalData.filter((r: any) => r.id === tableDocumentSelectedData[doc].id).length === 0) {
					const responseCreate = await TempDocumentsCreate({
						temp_document: {
							temp_type_id: body.id,
							document_type_id: tableDocumentSelectedData[doc].document_type_id
						}
					})
					if (!responseCreate) {
						alert("Error! Please advise your provider!")
					}
				}
			}
			for (const doc in tableDocumentSelectedOriginalData) {
				// Only delete the numbers that were removed
				if (tableDocumentSelectedData.filter((r: any) => r.id === tableDocumentSelectedOriginalData[doc].id).length === 0) {
					const responseDelete = await TempDocumentsDelete(tableDocumentSelectedOriginalData[doc].id)
					if (!responseDelete) {
						alert("Error! Please advise your provider!")
					}
				}
			}
			//
			setEditModalOpen(false)
		}
	}

	async function submitDelete(event: any) {
		//
		event.preventDefault()
		setEditModalLoading(true)
		//
		const response = await TempTypeDelete(formDataID)
		if (response.ok) {
			const tableDataUpdated: any = tableData.filter((tableDataRow: any) => {
				return tableDataRow.id !== formDataID
			})
			setTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			setShowMaintainError(true)
			setEditModalLoading(false)
		}
	}

	function formDataValidation() {
		if (formDataName.length > 0) {
			setValidName(true)
		} else {
			setValidName(false)
		}
	}

	function addDocumentSelection(createOrMaintain: "C" | "M", document_id: number) {
		let dataCopy = JSON.parse(JSON.stringify(tableDocumentSelectedData))
		if (createOrMaintain === "C") {
			dataCopy.push({
				id: 0,
				temp_type_id: null,
				document_type_id: document_id,
			})
		} else {
			// We may already have the temp_document they just turned on...
			const existingRecords = tableDocumentSelectedOriginalData.filter((r: any) => r.id === document_id)
			if (existingRecords.length > 0) {
				dataCopy.push(existingRecords[0])
			} else {
				dataCopy.push({
					id: 0,
					temp_type_id: formDataID,
					document_type_id: document_id,
				})
			}
		}
		setTableDocumentSelectedData(dataCopy)
	}

	function deleteDocumentSelection(createOrMaintain: "C" | "M", document_id: number) {
		let dataCopy = JSON.parse(JSON.stringify(tableDocumentSelectedData))
		if (createOrMaintain === "C") {
			dataCopy = dataCopy.filter((r: any) => r.document_type_id !== document_id)
		} else {
			dataCopy = dataCopy.filter((r: any) => r.document_type_id !== document_id)
		}
		setTableDocumentSelectedData(dataCopy)
	}

	return (
		<>
			<div className="text-2xl font-semibold">
				Temp Types
			</div>
			<div role="status" className="mt-8" hidden={!loadingData}>
				<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
				</svg>
			</div>
			<div hidden={loadingData}>
				<TemplateTable data={tableData} columns={columns} openCreateModal={openCreateModal} openMaintainModal={openMaintainModal} name="Temp Type" disableCreate={false} UnderComponent={() => <div></div>} />
			</div>
			<Transition.Root show={createModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100"
					initialFocus={cancelButtonRef}
					onClose={() => { }}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!createModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={createModalLoading} onSubmit={submitCreateForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Create Temp Type
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setCreateModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div hidden={!showCreateError} className="text-red-400 text-center">
														Something has gone wrong. Please try again.
													</div>
													<div className="space-y-12">
														<div className="border-b border-gray-900/10 pb-12">
															<div className="space-y-4 gap-y-8">
																<div>
																	<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
																		Name
																	</label>
																	<div className="mt-2">
																		<input
																			id="name"
																			name="name"
																			type="text"
																			autoComplete="name"
																			required
																			value={formDataName}
																			onChange={(e) => {
																				setFormDataName(e.target.value)
																				formDataValidation()
																			}}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
																{tableDocumentData.length === 0 && <div>No Documents detected. Please go and create one or more documents.</div>}
																{tableDocumentData.length > 0 && <div className="block text-sm font-medium leading-6 text-gray-900">
																	Required Documents
																</div>}
																{tableDocumentData.length > 0 && tableDocumentData.map((doc: any, index: number) => {
																	const currentlySelected = tableDocumentSelectedData.filter((r: any) => r.document_type_id === doc.id).length === 1
																	return <div key={index}>
																		<div className="mt-2">
																			<div className="flex items-center">
																				<button
																					id={doc.id}
																					type="button"
																					className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ${currentlySelected ? 'bg-blue-600' : 'bg-gray-200'}`}
																					role="switch"
																					aria-checked={currentlySelected ? 'true' : 'false'}
																					aria-labelledby="annual-billing-label"
																					onClick={() => {
																						if (currentlySelected) {
																							deleteDocumentSelection("C", doc.id)
																						} else {
																							addDocumentSelection("C", doc.id)
																						}
																					}}
																				>
																					<span aria-hidden="true" className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${currentlySelected ? 'translate-x-5' : 'translate-x-0'}`}></span>
																				</button>
																				<span className="ml-3 text-sm" id="annual-billing-label">
																					<label htmlFor={doc.id} className="font-medium text-gray-900">{doc.name}</label>
																				</span>
																			</div>
																		</div>
																	</div>
																})}
																<div>
																	<label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
																		Notes
																	</label>
																	<div className="mt-2">
																		<textarea
																			id="notes"
																			name="notes"
																			rows={4}
																			autoComplete="notes"
																			value={formDataNotes}
																			onChange={(e) => {
																				setFormDataNotes(e.target.value)
																				formDataValidation()
																			}}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Create
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setCreateModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root >

			<Transition.Root show={editModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={() => { }}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!editModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={editModalLoading} onSubmit={submitMaintainForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Maintain Temp Type
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setEditModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div className="space-y-4">
														<div hidden={!showMaintainError} className="text-red-400 text-center">
															Something has gone wrong
														</div>
														<div className="space-y-12">
															<div className="border-b border-gray-900/10 pb-12">
																<div className="space-y-4 gap-y-8">
																	<div>
																		<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
																			Name
																		</label>
																		<div className="mt-2">
																			<input
																				id="name"
																				name="name"
																				type="text"
																				autoComplete="name"
																				required
																				value={formDataName}
																				onChange={(e) => {
																					setFormDataName(e.target.value)
																					formDataValidation()
																				}}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																	{tableDocumentData.length === 0 && <div>No Documents detected. Please go and create one or more documents.</div>}
																	{tableDocumentData.length > 0 && <div className="block text-sm font-medium leading-6 text-gray-900">
																		Required Documents
																	</div>}
																	{tableDocumentData.length > 0 && tableDocumentData.map((doc: any, index: number) => {
																		const currentlySelected = tableDocumentSelectedData.filter((r: any) => r.document_type_id === doc.id).length === 1
																		return <div key={index}>
																			<div className="mt-2">
																				<div className="flex items-center">
																					<button
																						id={doc.id}
																						type="button"
																						className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ${currentlySelected ? 'bg-blue-600' : 'bg-gray-200'}`}
																						role="switch"
																						aria-checked={currentlySelected ? 'true' : 'false'}
																						aria-labelledby="annual-billing-label"
																						onClick={() => {
																							if (currentlySelected) {
																								deleteDocumentSelection("M", doc.id)
																							} else {
																								addDocumentSelection("M", doc.id)
																							}
																						}}
																					>
																						<span aria-hidden="true" className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${currentlySelected ? 'translate-x-5' : 'translate-x-0'}`}></span>
																					</button>
																					<span className="ml-3 text-sm" id="annual-billing-label">
																						<label htmlFor={doc.id} className="font-medium text-gray-900">{doc.name}</label>
																					</span>
																				</div>
																			</div>
																		</div>
																	})}
																	<div>
																		<label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
																			Notes
																		</label>
																		<div className="mt-2">
																			<textarea
																				id="notes"
																				name="notes"
																				rows={4}
																				autoComplete="notes"
																				value={formDataNotes}
																				onChange={(e) => {
																					setFormDataNotes(e.target.value)
																					formDataValidation()
																				}}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div>
															<button
																id="role"
																type="button"
																className="block w-full bg-red-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 outline-none"
																onClick={submitDelete}
															>
																Delete
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													// disabled={!validName}
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Update
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setEditModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

		</>
	)
}