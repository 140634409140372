import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function OfficeIndex() {
	return FetchRequestGET("/offices")
}

export async function OfficeShow(id: number) {
	return FetchRequestGET(`/offices/${id}`)
}

export async function OfficeUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/offices/${id}`,
		body
	)
}

export async function OfficeCreate(body: any) {
	return FetchRequestPOST(
		`/offices`,
		body
	)
}

export async function OfficeDelete(id: number) {
	return FetchRequestDELETE(
		`/offices/${id}`)
}






