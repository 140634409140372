import { FetchRequestGET } from "../api";

export async function PublicTempTypeIndex(orgName: string) {
	return FetchRequestGET(`/public/temp-types?org_name=${orgName}`)
}






