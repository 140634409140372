import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function TempTypeIndex() {
	return FetchRequestGET("/temp_types")
}

export async function TempTypeShow(id: number) {
	return FetchRequestGET(`/temp_types/${id}`)
}

export async function TempTypeUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/temp_types/${id}`,
		body
	)
}

export async function TempTypeCreate(body: any) {
	return FetchRequestPOST(
		`/temp_types`,
		body
	)
}

export async function TempTypeDelete(id: number) {
	return FetchRequestDELETE(
		`/temp_types/${id}`)
}






