import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
	ArrowLeftOnRectangleIcon,
	Bars3Icon,
	// CalendarIcon,
	// ChartPieIcon,
	Cog6ToothIcon,
	// FolderIcon,
	HomeIcon,
	UserIcon,
	UserPlusIcon,
	UsersIcon,
	XMarkIcon,
	BuildingOffice2Icon
} from '@heroicons/react/24/outline'
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'
import { GetSession } from '../../lib/backend/auth'

function navigation(currentPath: string) {
	return [
		{ type: "Normal", temp_allowed: true, name: 'Dashboard', href: '/home/dashboard', icon: HomeIcon, current: currentPath === "/home/dashboard" },
		// { type: "Normal", temp_allowed: false, name: 'Offices', href: '/home/offices', icon: BuildingOffice2Icon, current: currentPath === "/home/offices" },
		// { type: "Normal", temp_allowed: false, name: 'Users', href: '/home/users', icon: UsersIcon, current: currentPath === "/home/users" },
		{ type: "Temps", temp_allowed: false, name: 'All Temps', href: '/home/temps', icon: UserPlusIcon, current: currentPath === "/home/temps" },
		{ type: "Temps", temp_allowed: false, name: 'Entered Temps', href: '/home/temps-entered', icon: UserPlusIcon, current: currentPath === "/home/temps-entered" },
		{ type: "Temps", temp_allowed: false, name: 'Approved Temps', href: '/home/temps-approved', icon: UserPlusIcon, current: currentPath === "/home/temps-approved" },
		{ type: "Temps", temp_allowed: false, name: 'Rejected Temps', href: '/home/temps-rejected', icon: UserPlusIcon, current: currentPath === "/home/temps-rejected" },
		{ type: "Temps", temp_allowed: false, name: 'Blacklisted Temps', href: '/home/temps-blacklisted', icon: UserPlusIcon, current: currentPath === "/home/temps-blacklisted" },
		{ type: "Hiring", temp_allowed: false, name: 'Unfilled Hires', href: '/home/unfilled-temps', icon: UserPlusIcon, current: currentPath === "/home/unfilled-temps" },
		{ type: "Hiring", temp_allowed: false, name: 'Future Hires', href: '/home/future-temps', icon: UserPlusIcon, current: currentPath === "/home/future-temps" },
		{ type: "Hiring", temp_allowed: false, name: 'Past Hires', href: '/home/past-temps', icon: UserPlusIcon, current: currentPath === "/home/past-temps" },
		{ type: "HireRequests", temp_allowed: false, name: 'Open Hire Requests', href: '/home/open-hire-requests', icon: UserPlusIcon, current: currentPath === "/home/open-hire-requests" },
		{ type: "HireRequests", temp_allowed: false, name: 'Rejected Hire Requests', href: '/home/rejected-hire-requests', icon: UserPlusIcon, current: currentPath === "/home/rejected-hire-requests" },
		{ type: "Settings", temp_allowed: false, name: 'Document Types', href: '/home/settings/document-types', icon: UsersIcon, current: currentPath === "/home/settings/document-types" },
		{ type: "Settings", temp_allowed: false, name: 'Temp Types', href: '/home/settings/temp-types', icon: UsersIcon, current: currentPath === "/home/settings/temp-types" },
		// { name: 'Reports (Coming Soon)', href: '/home/reports', icon: ChartPieIcon, current: false },
	]
}

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function HomePage() {
	const [temp, setTemp] = useState("Y")
	const [sidebarOpen, setSidebarOpen] = useState(false)
	// const [offices, setOffices] = useState([
	// 	{ id: 0, name: '', href: '', initial: '', current: false },
	// 	// { id: 2, name: 'Melville', href: '#', initial: 'M', current: useLocation().pathname === "/home/dashboard2" },
	// 	// { id: 3, name: 'Fremantle', href: '#', initial: 'F', current: useLocation().pathname === "/home/dashboard2" },
	// ])

	const navigate = useNavigate();

	const currentPath = useLocation().pathname

	// async function officeChange() {
	// 	const { data, error } = await supabase.from("office").select("id,name")
	// 	if (!error && data.length > 0) {
	// 		const officeData = data.map(officeRow => {
	// 			return {
	// 				id: officeRow.id,
	// 				name: officeRow.name,
	// 				href: `/home/office/${officeRow.name}`,
	// 				initial: officeRow.name[1],
	// 				current: currentPath === `/home/office/${officeRow.name}`
	// 			}
	// 		})
	// 		setOffices(officeData)
	// 	}
	// }

	useEffect(() => {
		pageChange()
	}, [navigate, currentPath])

	async function pageChange() {
		const response = await GetSession()
		if (!response.ok) {
			navigate("/login")
		} else {
			const body = await response.json()
			setTemp(body.temp)
		}
	}

	return (
		<>
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="relative z-49 lg:hidden" onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-900/80" />
						</Transition.Child>

						<div className="fixed inset-0 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative w-full mr-16 flex max-w-xs flex-1">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
											<button id="sidebarButtonMobile" type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
												<span className="sr-only">Close sidebar</span>
												<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
									</Transition.Child>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
										<div className="flex h-16 shrink-0 items-center">
											<img
												className="h-8 w-auto mt-4"
												src="/temp-trackr-heading-logo-half.svg"
												alt="Temp Trackr"
											/>
										</div>
										<nav className="flex flex-1 flex-col">
											<ul className="flex flex-1 flex-col gap-y-7">
												<li>
													<ul className="-mx-2 space-y-1">
														{navigation(useLocation().pathname).filter((item) => item.type === "Normal" && (item.temp_allowed === true || temp === "N")).map((item) => (
															<li key={item.name}>
																<Link
																	id={`${item.name}Desktop`}
																	to={item.href}
																	onClick={() => setSidebarOpen(false)}
																	className={classNames(
																		item.current
																			? 'bg-indigo-700 text-white'
																			: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}
																>
																	<item.icon
																		className={classNames(
																			item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
																			'h-6 w-6 shrink-0'
																		)}
																		aria-hidden="true"
																	/>
																	{item.name}
																</Link>
															</li>
														))}
													</ul>
												</li>
												{/* <li>
													<div className="text-xs font-semibold leading-6 text-indigo-200">Your Offices</div>
													<ul className="-mx-2 mt-2 space-y-1">
														{offices.length > 0 && offices[0].id !== 0 && offices.map((team) => (
															<li key={team.name}>
																<Link
																	to={team.href}
																	onClick={() => setSidebarOpen(false)}
																	className={classNames(
																		team.current
																			? 'bg-indigo-700 text-white'
																			: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}
																>
																	<span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-indigo-400 bg-indigo-500 text-[0.625rem] font-medium text-white">
																		{team.initial}
																	</span>
																	<span className="truncate">{team.name}</span>
																</Link>
															</li>
														))}
													</ul>
												</li> */}
												<li>
													<div className="text-xs font-semibold leading-6 text-indigo-200">{temp === "N" ? "Temps" : ""}</div>
													<ul className="-mx-2 mt-2 space-y-1">
														{navigation(useLocation().pathname).filter(item => item.type === "Temps" && (item.temp_allowed === true || temp === "N")).map((item) => (
															<li key={item.name}>
																<Link
																	to={item.href}
																	onClick={() => setSidebarOpen(false)}
																	className={classNames(
																		item.current
																			? 'bg-indigo-700 text-white'
																			: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}
																>
																	<span className="truncate">{item.name}</span>
																</Link>
															</li>
														))}
													</ul>
												</li>
												<li>
													<div className="text-xs font-semibold leading-6 text-indigo-200">{temp === "N" ? "Hiring" : ""}</div>
													<ul className="-mx-2 mt-2 space-y-1">
														{navigation(useLocation().pathname).filter(item => item.type === "Hiring" && (item.temp_allowed === true || temp === "N")).map((item) => (
															<li key={item.name}>
																<Link
																	to={item.href}
																	onClick={() => setSidebarOpen(false)}
																	className={classNames(
																		item.current
																			? 'bg-indigo-700 text-white'
																			: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}
																>
																	<span className="truncate">{item.name}</span>
																</Link>
															</li>
														))}
													</ul>
												</li>
												<li>
													<div className="text-xs font-semibold leading-6 text-indigo-200">{temp === "N" ? "Hire Requests" : ""}</div>
													<ul className="-mx-2 mt-2 space-y-1">
														{navigation(useLocation().pathname).filter(item => item.type === "HireRequests" && (item.temp_allowed === true || temp === "N")).map((item) => (
															<li key={item.name}>
																<Link
																	to={item.href}
																	onClick={() => setSidebarOpen(false)}
																	className={classNames(
																		item.current
																			? 'bg-indigo-700 text-white'
																			: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}
																>
																	<span className="truncate">{item.name}</span>
																</Link>
															</li>
														))}
													</ul>
												</li>
												<li className="mt-auto">
													<div className="text-xs font-semibold leading-6 text-indigo-200">{temp === "N" ? "Settings" : ""}</div>
													<ul className="-mx-2 mt-2 space-y-1">
														{navigation(useLocation().pathname).filter(item => item.type === "Settings" && (item.temp_allowed === true || temp === "N")).map((item) => (
															<li key={item.name}>
																<Link
																	to={item.href}
																	onClick={() => setSidebarOpen(false)}
																	className={classNames(
																		item.current
																			? 'bg-indigo-700 text-white'
																			: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}
																>
																	<span className="truncate">{item.name}</span>
																</Link>
															</li>
														))}
													</ul>
												</li>
												<li>
													<ul className="-mx-2 space-y-1">
														<li>
															<Link
																id="accountMobile"
																to="/home/account"
																onClick={() => setSidebarOpen(false)}
																className={classNames(
																	useLocation().pathname === "/home/account"
																		? 'bg-indigo-700 text-white'
																		: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																	'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																)}
															// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
															>
																<UserIcon
																	className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
																	aria-hidden="true"
																/>
																Account
															</Link>
														</li>
														<li hidden={temp === "Y"}>
															<Link
																id="organisationsMobile"
																to="/home/organisation"
																onClick={() => setSidebarOpen(false)}
																className={classNames(
																	useLocation().pathname === "/home/organisation"
																		? 'bg-indigo-700 text-white'
																		: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																	'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																)}
															// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white my-0"
															>
																<Cog6ToothIcon
																	className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
																	aria-hidden="true"
																/>
																Organisation
															</Link>
														</li>
														<li hidden={temp === "Y"}>
															<Link
																id="officesMobile"
																to="/home/offices"
																onClick={() => setSidebarOpen(false)}
																className={classNames(
																	useLocation().pathname === "/home/offices"
																		? 'bg-indigo-700 text-white'
																		: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																	'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																)}
															// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white my-0"
															>
																<BuildingOffice2Icon
																	className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
																	aria-hidden="true"
																/>
																Offices
															</Link>
														</li>
														<li hidden={temp === "Y"}>
															<Link
																to="/home/users"
																onClick={() => setSidebarOpen(false)}
																className={classNames(
																	useLocation().pathname === "/home/users"
																		? 'bg-indigo-700 text-white'
																		: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
																	'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																)}
															// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white my-0"
															>
																<UsersIcon
																	className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
																	aria-hidden="true"
																/>
																Users
															</Link>
														</li>
														<li className="">
															<Link
																id="logoutDesktop"
																to="/logout"
																className="text-indigo-200 hover:text-white hover:bg-indigo-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
															// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-red-700 hover:text-white"
															>
																<ArrowLeftOnRectangleIcon
																	className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
																	aria-hidden="true"
																/>
																Logout
															</Link>
														</li>
													</ul>
												</li>
											</ul>
										</nav>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:fixed lg:inset-y-0 lg:z-49 lg:flex lg:w-72 lg:flex-col">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
						<div className="flex h-16 shrink-0 items-center">
							<img
								className="h-8 w-auto mt-4"
								src="/temp-trackr-heading-logo-half.svg"
								alt="Temp Trackr"
							/>
						</div>
						<nav className="flex flex-1 flex-col">
							<ul className="flex flex-1 flex-col gap-y-7">
								<li>
									<ul className="-mx-2 space-y-1">
										{navigation(useLocation().pathname).filter(item => item.type === "Normal" && (item.temp_allowed === true || temp === "N")).map((item) => (
											<li key={item.name}>
												<Link
													id={`${item.name}Mobile`}
													to={item.href}
													onClick={() => setSidebarOpen(false)}
													className={classNames(
														item.current
															? 'bg-indigo-700 text-white'
															: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<item.icon
														className={classNames(
															item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
															'h-6 w-6 shrink-0'
														)}
														aria-hidden="true"
													/>
													{item.name}
												</Link>
											</li>
										))}
									</ul>
								</li>
								{/* <li>
									<div className="text-xs font-semibold leading-6 text-indigo-200">Your Offices</div>
									<ul className="-mx-2 mt-2 space-y-1">
										{offices.length > 0 && offices[0].id !== 0 && offices.map((team) => (
											<li key={team.name}>
												<Link
													to={team.href}
													onClick={() => setSidebarOpen(false)}
													className={classNames(
														team.current
															? 'bg-indigo-700 text-white'
															: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-indigo-400 bg-indigo-500 text-[0.625rem] font-medium text-white">
														{team.initial}
													</span>
													<span className="truncate">{team.name}</span>
												</Link>
											</li>
										))}
									</ul>
								</li> */}
								<li>
									<div className="text-xs font-semibold leading-6 text-indigo-200">{temp === "N" ? "Temps" : ""}</div>
									<ul className="-mx-2 mt-2 space-y-1">
										{navigation(useLocation().pathname).filter(item => item.type === "Temps" && (item.temp_allowed === true || temp === "N")).map((item) => (
											<li key={item.name}>
												<Link
													to={item.href}
													onClick={() => setSidebarOpen(false)}
													className={classNames(
														item.current
															? 'bg-indigo-700 text-white'
															: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<span className="truncate">{item.name}</span>
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li>
									<div className="text-xs font-semibold leading-6 text-indigo-200">{temp === "N" ? "Hiring" : ""}</div>
									<ul className="-mx-2 mt-2 space-y-1">
										{navigation(useLocation().pathname).filter(item => item.type === "Hiring" && (item.temp_allowed === true || temp === "N")).map((item) => (
											<li key={item.name}>
												<Link
													to={item.href}
													onClick={() => setSidebarOpen(false)}
													className={classNames(
														item.current
															? 'bg-indigo-700 text-white'
															: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<span className="truncate">{item.name}</span>
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li>
									<div className="text-xs font-semibold leading-6 text-indigo-200">{temp === "N" ? "Hire Requests" : ""}</div>
									<ul className="-mx-2 mt-2 space-y-1">
										{navigation(useLocation().pathname).filter(item => item.type === "HireRequests" && (item.temp_allowed === true || temp === "N")).map((item) => (
											<li key={item.name}>
												<Link
													to={item.href}
													onClick={() => setSidebarOpen(false)}
													className={classNames(
														item.current
															? 'bg-indigo-700 text-white'
															: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<span className="truncate">{item.name}</span>
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li className="mt-auto">
									<div className="text-xs font-semibold leading-6 text-indigo-200">{temp === "N" ? "Settings" : ""}</div>
									<ul className="-mx-2 mt-2 space-y-1">
										{navigation(useLocation().pathname).filter(item => item.type === "Settings" && (item.temp_allowed === true || temp === "N")).map((item) => (
											<li key={item.name}>
												<Link
													to={item.href}
													onClick={() => setSidebarOpen(false)}
													className={classNames(
														item.current
															? 'bg-indigo-700 text-white'
															: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}
												>
													<span className="truncate">{item.name}</span>
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li>
									<ul className="-mx-2 space-y-1">
										<li>
											<Link
												to="/home/account"
												className={classNames(
													useLocation().pathname === "/home/account"
														? 'bg-indigo-700 text-white'
														: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
													'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
												)}
											// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
											>
												<UserIcon
													className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
													aria-hidden="true"
												/>
												Account
											</Link>
										</li>
										<li hidden={temp === "Y"}>
											<Link
												to="/home/organisation"
												onClick={() => setSidebarOpen(false)}
												className={classNames(
													useLocation().pathname === "/home/organisation"
														? 'bg-indigo-700 text-white'
														: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
													'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
												)}
											// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white my-0"
											>
												<Cog6ToothIcon
													className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
													aria-hidden="true"
												/>
												Organisation
											</Link>
										</li>
										<li hidden={temp === "Y"}>
											<Link
												id="officesDesktop"
												to="/home/offices"
												onClick={() => setSidebarOpen(false)}
												className={classNames(
													useLocation().pathname === "/home/offices"
														? 'bg-indigo-700 text-white'
														: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
													'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
												)}
											// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white my-0"
											>
												<BuildingOffice2Icon
													className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
													aria-hidden="true"
												/>
												Offices
											</Link>
										</li>
										<li hidden={temp === "Y"}>
											<Link
												to="/home/users"
												onClick={() => setSidebarOpen(false)}
												className={classNames(
													useLocation().pathname === "/home/users"
														? 'bg-indigo-700 text-white'
														: 'text-indigo-200 hover:text-white hover:bg-indigo-700',
													'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
												)}
											// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white my-0"
											>
												<UsersIcon
													className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
													aria-hidden="true"
												/>
												Users
											</Link>
										</li>
										<li className="w-full">
											<Link
												id="logoutMobile"
												to="/logout"
												className="text-indigo-200 hover:text-white hover:bg-indigo-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
											// className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-red-700 hover:text-white"
											>
												<ArrowLeftOnRectangleIcon
													className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
													aria-hidden="true"
												/>
												Logout
											</Link>
										</li>
									</ul>
								</li>
							</ul>
						</nav>
					</div>
				</div>

				<div className="lg:pl-72">
					<div className="sticky top-0 z-8 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
						<button id="sidebarButtonDesktop" type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
							<span className="sr-only">Open sidebar</span>
							<Bars3Icon className="h-6 w-6" aria-hidden="true" />
						</button>

						{/* Separator */}
						<div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

						<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
							<div className="relative flex flex-1 text-2xl font-bold items-center">
								Temp Trackr
							</div>
							<div className="flex items-center gap-x-4 lg:gap-x-6">
								<div></div>
								{/* <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
									<span className="sr-only">View notifications</span>
									<BellIcon className="h-6 w-6" aria-hidden="true" />
								</button> */}

								{/* Separator */}
								{/* <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" /> */}

								{/* Profile dropdown */}
								{/* <Menu as="div" className="relative">
									<Menu.Button className="-m-1.5 flex items-center p-1.5">
										<span className="sr-only">Open user menu</span>
										<img
											className="h-8 w-8 rounded-full bg-gray-50"
											src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
											alt=""
										/>
										<span className="hidden lg:flex lg:items-center">
											<span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
												Tom Cook
											</span>
											<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
										</span>
									</Menu.Button>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											{userNavigation.map((item) => (
												<Menu.Item key={item.name}>
													{({ active }) => (
														<Link
															to={item.href}
															className={classNames(
																active ? 'bg-gray-50' : '',
																'block px-3 py-1 text-sm leading-6 text-gray-900'
															)}
														>
															{item.name}
														</Link>
													)}
												</Menu.Item>
											))}
										</Menu.Items>
									</Transition>
								</Menu> */}
							</div>
						</div>
					</div>

					<main className="py-10">
						<div className="px-4 sm:px-6 lg:px-8">
							<Outlet />
						</div>
					</main>
				</div>
			</div>
		</>
	)
}
