import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function DocumentTypeIndex() {
	return FetchRequestGET("/document_types")
}

export async function DocumentTypeShow(id: number) {
	return FetchRequestGET(`/document_types/${id}`)
}

export async function DocumentTypeUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/document_types/${id}`,
		body
	)
}

export async function DocumentTypeCreate(body: any) {
	return FetchRequestPOST(
		`/document_types`,
		body
	)
}

export async function DocumentTypeDelete(id: number) {
	return FetchRequestDELETE(
		`/document_types/${id}`)
}






