import { FetchRequestDELETE, FetchRequestGET, FetchRequestPATCH, FetchRequestPOST } from "../api";

export async function TempDocumentsIndex() {
	return FetchRequestGET("/temp_documents")
}

export async function TempDocumentsShow(id: number) {
	return FetchRequestGET(`/temp_documents/${id}`)
}

export async function TempDocumentsUpdate(id: number, body: any) {
	return FetchRequestPATCH(
		`/temp_documents/${id}`,
		body
	)
}

export async function TempDocumentsCreate(body: any) {
	return FetchRequestPOST(
		`/temp_documents`,
		body
	)
}

export async function TempDocumentsDelete(id: number) {
	return FetchRequestDELETE(
		`/temp_documents/${id}`)
}






