import { useEffect, useState } from "react"
// import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { OrganisationShow, OrganisationUpdate } from "../../lib/backend/organisation"
import { pageUrl } from "../../lib/environment"

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

export default function OrganisationPage() {

	const [currentTab, setCurrentTab] = useState("Basic Info")

	const [formDataOrgName, setFormDataOrgName] = useState("")
	const [formDataOrgEmail, setFormDataOrgEmail] = useState("")
	const [formDataOrgLink, setFormDataOrgLink] = useState("")
	// const [formDataOrgCountry, setOrgCountry] = useState("")
	const [formDataOrgAddress, setFormDataOrgAddress] = useState("")
	const [formDataOrgCity, setFormDataOrgCity] = useState("")
	const [formDataOrgState, setFormDataOrgState] = useState("")
	const [formDataOrgZip, setFormDataOrgZip] = useState("")

	const [loading, setLoading] = useState(true)

	const tabs = [
		{ name: 'Basic Info' },
		{ name: 'Something Else...' },
	]

	useEffect(() => {
		pageLoad()
	}, [])

	async function pageLoad() {
		const response = await OrganisationShow()
		if (response.ok) {
			const body = await response.json()
			//
			setFormDataOrgName(body.name || "")
			setFormDataOrgEmail(body.email || "")
			setFormDataOrgLink(body.link || "")
			// setOrgCountry(body.country || "")
			setFormDataOrgAddress(body.street_address || "")
			setFormDataOrgCity(body.city || "")
			setFormDataOrgState(body.state_province || "")
			setFormDataOrgZip(body.zip_postal_code || "")
			//
			setLoading(false)
		}
	}

	async function changeSection(newSection: string) {
		setCurrentTab(newSection)
	}

	async function submitOrganisationSettings(event: any) {
		//
		event.preventDefault()
		//
		setLoading(true)
		//
		const response = await OrganisationUpdate({
			name: formDataOrgName,
			email: formDataOrgEmail,
			link: formDataOrgLink,
			// country: formDataOrgCountry,
			street_address: formDataOrgAddress,
			city: formDataOrgCity,
			state_province: formDataOrgState,
			zip_postal_code: formDataOrgZip,
		})
		if (response.ok) {
			const body = await response.json()
			setFormDataOrgName(body.name)
			setFormDataOrgEmail(body.email)
			setFormDataOrgLink(body.link)
			// setOrgCountry(body.country)
			setFormDataOrgAddress(body.street_address)
			setFormDataOrgCity(body.city)
			setFormDataOrgState(body.state_province)
			setFormDataOrgZip(body.zip_postal_code)
			setLoading(false)
		}
		//
	}

	return (
		<>
			<div className="text-2xl font-semibold mb-4">
				Organisation
			</div>
			<div>
				<div className="sm:hidden">
					<label htmlFor="tabs" className="sr-only">
						Select a tab
					</label>
					<select
						id="tabs"
						name="tabs"
						onChange={(e) => changeSection(e.target.value)}
						className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
						defaultValue={currentTab}
					>
						{tabs.map((tab) => (
							<option key={tab.name}>{tab.name}</option>
						))}
					</select>
				</div>
				<div className="hidden sm:block">
					<div className="border-b border-gray-200">
						<nav className="-mb-px flex space-x-8" aria-label="Tabs">
							{tabs.map((tab) => (
								<div
									key={tab.name}
									onClick={() => changeSection(tab.name)}
									className={classNames(
										currentTab === tab.name
											? 'border-blue-500 text-blue-600'
											: 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
										'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
									)}
									aria-current={currentTab === tab.name ? 'page' : undefined}
								>
									{tab.name}
								</div>
							))}
						</nav>
					</div>
				</div>
			</div>

			<div className="mt-4 flex flex-col">
				<div hidden={!(currentTab === "Basic Info")}>
					<form onSubmit={submitOrganisationSettings}>
						<div className="space-y-12">
							<div className="border-b border-gray-900/10 pb-12 mt-4">
								<h2 className="text-base font-semibold leading-7 text-gray-900">Organisation Information</h2>
								<div className="w-full" hidden={!loading}>
									<div className="flex w-full justify-center">
										<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
											<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
										</svg>
									</div>
								</div>
								<div hidden={loading} className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
									<div className="sm:col-span-4">
										<label htmlFor="organisation-name" className="block text-sm font-medium leading-6 text-gray-900">
											Organisation name
										</label>
										<div className="mt-2">
											<input
												type="text"
												name="organisation-name"
												id="organisation-name"
												autoComplete="organisation name"
												onChange={(e) => setFormDataOrgName(e.target.value)}
												value={formDataOrgName}
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>

									<div className="sm:col-span-4">
										<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
											Email address
										</label>
										<div className="mt-2">
											<input
												id="email"
												name="email"
												type="email"
												autoComplete="email"
												onChange={(e) => setFormDataOrgEmail(e.target.value)}
												value={formDataOrgEmail}
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>

								</div>
							</div>

							<div className="border-b border-gray-900/10 pb-12 mt-4">
								<h2 className="text-base font-semibold leading-7 text-gray-900">Organisation Link</h2>
								<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
									<div className="sm:col-span-4">
										<label htmlFor="organisation-link" className="block text-sm font-medium leading-6 text-gray-900">
											Organisation link (We recommend your organisation name)
										</label>
										<div className="mt-2">
											<input
												type="text"
												name="organisation-link"
												id="organisation-link"
												autoComplete="organisation link"
												onChange={(e) => setFormDataOrgLink(e.target.value.replace(/[^a-z]/gi, ''))}
												value={formDataOrgLink}
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
											/>
										</div>
										<div className="mt-2 text-blue-500">
											{`${pageUrl()}/signup/${formDataOrgLink}`}
										</div>
									</div>

								</div>
							</div>

							<div className="border-b border-gray-900/10 pb-12">
								<h2 className="text-base font-semibold leading-7 text-gray-900">Organisation Address</h2>

								<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

									{/* <div className="sm:col-span-3">
										<label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
											Country
										</label>
										<div className="mt-2">
											<select
												id="country"
												name="country"
												autoComplete="country-name"
												onChange={(e) => setOrgCountry(e.target.value)}
												value={formDataOrgCountry}
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
											>
												<option>United States</option>
											</select>
										</div>
									</div> */}

									<div className="col-span-full">
										<label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
											Street address
										</label>
										<div className="mt-2">
											<input
												type="text"
												name="street-address"
												id="street-address"
												autoComplete="street-address"
												onChange={(e) => setFormDataOrgAddress(e.target.value)}
												value={formDataOrgAddress}
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>

									<div className="sm:col-span-2 sm:col-start-1">
										<label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
											City
										</label>
										<div className="mt-2">
											<input
												type="text"
												name="city"
												id="city"
												autoComplete="address-level2"
												onChange={(e) => setFormDataOrgCity(e.target.value)}
												value={formDataOrgCity}
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>

									<div className="sm:col-span-2">
										<label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
											State / Province
										</label>
										<div className="mt-2">
											<input
												type="text"
												name="region"
												id="region"
												autoComplete="address-level1"
												onChange={(e) => setFormDataOrgState(e.target.value)}
												value={formDataOrgState}
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>

									<div className="sm:col-span-2">
										<label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
											ZIP / Postal code
										</label>
										<div className="mt-2">
											<input
												type="text"
												name="postal-code"
												id="postal-code"
												autoComplete="postal-code"
												onChange={(e) => setFormDataOrgZip(e.target.value)}
												value={formDataOrgZip}
												className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="mt-6 flex items-center justify-end gap-x-6">
							<button
								type="submit"
								className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
							>
								Save Changes
							</button>
						</div>
					</form>
				</div>
			</div>

		</>
	)
}
