import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TemplateTable from "../../components/TemplateTable"
import { XMarkIcon, ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { TempCreate, TempDelete, TempIndex, TempShow, TempUpdate } from '../../lib/backend/temp'
import { FileIndexUser } from '../../lib/backend/fileUpload'
import TemplateFileUpload from '../../components/TemplateFileUpload'
import SelectElement from '../../components/SelectElement'

const columns = [
	{
		header: "Temp Type",
		accessorKey: "temp_type_name"
	},
	{
		header: "First Name",
		accessorKey: "first_name",
	},
	{
		header: "Last Name",
		accessorKey: "last_name",
	},
	{
		header: "Status",
		accessorKey: "temp_status",
	},
]
const statuses = [
	{
		value: "all",
		label: "All"
	},
	{
		value: "Entered",
		label: "Entered"
	},
	{
		value: "Submitted Docs",
		label: "Submitted Docs"
	},
]
export default function TempsPageEntered() {
	const [loadingData, setLoadingData] = useState(true)
	const [tableData, setTableData] = useState<any>([])
	const [originalTableData, setOriginalTableData] = useState<any>([])

	const [files, setFiles] = useState([])
	const [filesLoading, setFilesLoading] = useState(true)


	const [createModalOpen, setCreateModalOpen] = useState(false)
	const [createModalLoading, setcreateModalLoading] = useState(false)
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [editModalLoading, setEditModalLoading] = useState(false)
	const [approveConfirmOpen, setApproveConfirmOpen] = useState(false)
	const [rejectConfirmOpen, setRejectConfirmOpen] = useState(false)
	const [showCreateError, setShowCreateError] = useState(false)
	const [showMaintainError, setShowMaintainError] = useState(false)

	const [validName, setValidName] = useState(false)

	const [formDataID, setFormDataID] = useState(0)
	//
	const [formDataEmail, setFormDataEmail] = useState("")
	const [formDataFirstName, setFormDataFirstName] = useState("")
	const [formDataLastName, setFormDataLastName] = useState("")
	const [formDataPhoneNumber, setFormDataPhoneNumber] = useState("")
	//
	const [formDataStreetAddress, setFormDataStreetAddress] = useState("")
	const [formDataCity, setFormDataCity] = useState("")
	const [formDataStateProvince, setFormDataStateProvince] = useState("")
	const [formDataZipPostalCode, setFormDataZipPostalCode] = useState("")
//
	const [formDataNotes, setFormDataNotes] = useState("")
	//
	const [formDataTempTypeName, setFormDataTempTypeName] = useState("")

	// const [formDataName, setFormDataName] = useState("")
	// const [formCountry, setFormCountry] = useState("")
	// const [formStreetAddress, setFormStreetAddress] = useState("")
	// const [formCity, setFormCity] = useState("")
	// const [formStateProvince, setFormStateProvince] = useState("")
	// const [formZipPostalCode, setFormZipPostalCode] = useState("")

	const [statusType, setStatusType] = useState({ value: "all", label: "All" })
	
	const cancelButtonRef = useRef(null)

	useEffect(() => {
		//
		setTableData(originalTableData.filter((row: any) => {
			// console.log(tempType.value)
			// console.log(statusType.value)
			if (statusType.value === "all") {
				return true
			}
				if (statusType.value === row.temp_status) {
					return true
				}
			return false
		}))
		//
	}, [statusType, originalTableData])

	if (loadingData === true) {
		getData()
	}

	async function getData() {
		//
		const response = await TempIndex("Entered")
		//
		const body = await response.json()
		//
		setLoadingData(false)
		setTableData(body);
		setOriginalTableData(body);
	}

	async function getFilesData(id: any) {
		//
		// console.log("getFilesData called")
		let userID
		if (id) {
			userID = id
		} else {
			userID = formDataID
		}
		// console.log({id})
		// console.log({formDataID})
		// console.log({userID})
		//
		setFilesLoading(true)
		const responseIndex = await FileIndexUser(userID)
		const bodyIndex = await responseIndex.json()
		// console.log({bodyIndex})
		setFiles(bodyIndex)
		setFilesLoading(false)
		// bodyIndex.forEach((file: any) => {
		// 	if (file.filename === "None") {
		// 		setShowModal(true)
		// 	}
		// })
	}

	function openCreateModal() {
		//
		setFormDataEmail("")
		setFormDataFirstName("")
		setFormDataLastName("")
		setFormDataPhoneNumber("")
		setFormDataStreetAddress("")
		setFormDataCity("")
		setFormDataStateProvince("")
		setFormDataZipPostalCode("")
//
		setFormDataNotes("")
		//
		setcreateModalLoading(false)
		setCreateModalOpen(true)
		setShowCreateError(false)
	}

	async function openMaintainModal(id: number) {
		//
		setEditModalLoading(true)
		setEditModalOpen(true)
		//
		setFormDataID(id)
getFilesData(id)
		//
		const response = await TempShow(id)
		const body = await response.json()
		//
		setFormDataEmail(body.email)
		setFormDataFirstName(body.first_name)
		setFormDataLastName(body.last_name)
		setFormDataPhoneNumber(body.phone_number)
		//
		setFormDataStreetAddress(body.street_address)
		setFormDataCity(body.city)
		setFormDataStateProvince(body.state_province)
		setFormDataZipPostalCode(body.zip_postal_code)
//
		setFormDataNotes(body.notes || "")
		//
		setFormDataTempTypeName(body.temp_type_name)
		//
		formDataValidation()
		//
		setEditModalLoading(false)
		//
	}

	async function submitMaintainForm(event: any) {
		//
		event.preventDefault();
		//
		setEditModalLoading(true)
		setShowMaintainError(false)
		//
		const response = await TempUpdate(formDataID, {
			user: {
				//
				email: formDataEmail,
				first_name: formDataFirstName,
				last_name: formDataLastName,
				phone_number: formDataPhoneNumber,
				//
				street_address: formDataStreetAddress,
				city: formDataCity,
				state_province: formDataStateProvince,
				zip_postal_code: formDataZipPostalCode,
				//
notes: formDataNotes,
				//
			}
		})
		//
		if (response.ok) {
			const body = await response.json()
			const tableDataUpdated: any = tableData.map((tableDataRow: any) => {
				if (tableDataRow.id === formDataID) {
					tableDataRow.first_name = body.first_name
					tableDataRow.last_name = body.last_name
				}
				return tableDataRow
			})
			setTableData(tableDataUpdated)
			setEditModalOpen(false)
		}
	}

	async function submitDelete(event: any) {
		//
		event.preventDefault()
		setEditModalLoading(true)
		//
		const response = await TempDelete(formDataID)
		//
		if (response.ok) {
			const tableDataUpdated: any = tableData.filter((tableDataRow: any) => {
				return tableDataRow.id !== formDataID
			})
			setTableData(tableDataUpdated)
			setEditModalOpen(false)
		} else {
			setShowMaintainError(true)
			setEditModalLoading(false)
		}

	}

	async function submitApprove() {
		//
		setEditModalLoading(true)
		//
		const response = await TempUpdate(formDataID, {
			user: {
				//
				temp_status: "Approved"
				//
			}
		})
		//
		if (response.ok) {
			const tableDataUpdated: any = tableData.filter((tableDataRow: any) => {
				return tableDataRow.id !== formDataID
			})
			setTableData(tableDataUpdated)
			setEditModalOpen(false)
			setApproveConfirmOpen(false)
		} else {
			setShowMaintainError(true)
			setEditModalLoading(false)
		}
	}

	async function submitReject(event: any) {
		//
		// event.preventDefault()
		setEditModalLoading(true)
		//
		const response = await TempUpdate(formDataID, {
			user: {
				//
				temp_status: "Rejected"
				//
			}
		})
		//
		if (response.ok) {
			const tableDataUpdated: any = tableData.filter((tableDataRow: any) => {
				return tableDataRow.id !== formDataID
			})
			setTableData(tableDataUpdated)
			setEditModalOpen(false)
			setRejectConfirmOpen(false)
		} else {
			setShowMaintainError(true)
			setEditModalLoading(false)
		}
	}

	function formDataValidation() {
		// if (formDataName.length > 0) {
		// 	setValidName(true)
		// } else {
		// 	setValidName(false)
		// }
	}

	return (
		<>
			<div className="text-2xl font-semibold">
				Entered Temps
			</div>
			<div role="status" className="mt-8" hidden={!loadingData}>
				<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
					<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
				</svg>
			</div>
			<div hidden={loadingData}>
				<TemplateTable data={tableData} columns={columns} openCreateModal={openCreateModal} openMaintainModal={openMaintainModal} name="Temp" disableCreate={true} UnderComponent={() => {
					return <div className="sm:flex sm:items-end w-full mt-5 gap-4">
						<SelectElement items={statuses} selected={statusType} setSelected={setStatusType} title="Temp Status" ></SelectElement>
					</div>
				}} />
			</div>
			{/* <Transition.Root show={createModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100"
					initialFocus={cancelButtonRef}
					onClose={() => { }}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!createModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={createModalLoading} onSubmit={submitCreateForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full sm:max-w-sm">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Create Temp
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setCreateModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div hidden={!showCreateError} className="text-red-400 text-center">
														Something has gone wrong. Please try again.
													</div>
													<div className="space-y-12">
														<div className="border-b border-gray-900/10 pb-12 mt-4">
															<h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
															<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

																<div className="sm:col-span-2">
																	<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
																		Email address
																	</label>
																	<div className="mt-2">
																		<input
																			id="email"
																			name="email"
																			type="email"
																			autoComplete="email"
																			onChange={(e) => setFormDataEmail(e.target.value)}
																			value={formDataEmail}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
																<div className="sm:col-span-1">
																	<label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
																		First Name
																	</label>
																	<div className="mt-2">
																		<input
																			id="first_name"
																			name="first_name"
																			type="text"
																			autoComplete="first_name"
																			onChange={(e) => setFormDataFirstName(e.target.value)}
																			value={formDataFirstName}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
																<div className="sm:col-span-1">
																	<label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
																		Last Name
																	</label>
																	<div className="mt-2">
																		<input
																			id="last_name"
																			name="last_name"
																			type="text"
																			autoComplete="last_name"
																			onChange={(e) => setFormDataLastName(e.target.value)}
																			value={formDataLastName}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
																<div className="sm:col-span-1">
																	<label htmlFor="phone_number" className="block text-sm font-medium leading-6 text-gray-900">
																		Phone number
																	</label>
																	<div className="mt-2">
																		<input
																			id="phone_number"
																			name="phone_number"
																			type="text"
																			autoComplete="phone_number"
																			onChange={(e) => setFormDataPhoneNumber(e.target.value)}
																			value={formDataPhoneNumber}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

															</div>
														</div>

														<div className="border-b border-gray-900/10 pb-12">
															<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

																<div className="col-span-full">
																	<label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
																		Street address
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="street-address"
																			id="street-address"
																			autoComplete="street-address"
																			onChange={(e) => setFormDataStreetAddress(e.target.value)}
																			value={formDataStreetAddress}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

																<div className="sm:col-span-2 sm:col-start-1">
																	<label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
																		City
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="city"
																			id="city"
																			autoComplete="address-level2"
																			onChange={(e) => setFormDataCity(e.target.value)}
																			value={formDataCity}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

																<div className="sm:col-span-2">
																	<label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
																		State / Province
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="region"
																			id="region"
																			autoComplete="address-level1"
																			onChange={(e) => setFormDataStateProvince(e.target.value)}
																			value={formDataStateProvince}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>

																<div className="sm:col-span-2">
																	<label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
																		ZIP / Postal code
																	</label>
																	<div className="mt-2">
																		<input
																			type="text"
																			name="postal-code"
																			id="postal-code"
																			autoComplete="postal-code"
																			onChange={(e) => setFormDataZipPostalCode(e.target.value)}
																			value={formDataZipPostalCode}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Create
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setCreateModalOpen(false)}
													ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root >
			*/}

			<Transition.Root show={editModalOpen} as={Fragment}>
				<Dialog as="div" className="relative z-100" onClose={() => { }}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-100 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
									<div className="w-full" hidden={!editModalLoading}>
										<div className="flex w-full justify-center">
											<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
												<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
											</svg>
										</div>
									</div>
									<form hidden={editModalLoading} onSubmit={submitMaintainForm}>
										<div className="my-1 sm:mt-1">
											<div className="my-1 flex min-h-full flex-1 flex-col justify-center py-2 lg:px-0">
												<div className="sm:mx-auto sm:w-full">
													<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
														Maintain Temp
													</h2>
												</div>
												<div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
														onClick={() => setEditModalOpen(false)}
													>
														<span className="sr-only">Close</span>
														<XMarkIcon className="h-6 w-6" aria-hidden="true" />
													</button>
												</div>

												<div className="my-4 sm:mx-auto sm:w-full">
													<div className="space-y-4">
														<div hidden={!showMaintainError} className="text-red-400 text-center">
															Something has gone wrong
														</div>
														<div className="space-y-12">
															<div className="mt-4">
																<div className="sm:col-span-2">
																	<label htmlFor="temp-type-name" className="block text-sm font-medium leading-6 text-gray-900">
																		Temp type
																	</label>
																	<div className="mt-2">
																		<input
																			id="temp-type-name"
																			name="temp-type-name"
																			type="text"
																			autoComplete="temp-type-name"
																			disabled={true}
																			// onChange={(e) => setFormDataTempTypeName(e.target.value)}
																			value={formDataTempTypeName}
																			className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																		/>
																	</div>
																</div>
															</div>
															<div className="">
																<h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
																<div className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2">

																	<div className="sm:col-span-2">
																		<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
																			Email address
																		</label>
																		<div className="mt-2">
																			<input
																				id="email"
																				name="email"
																				type="email"
																				autoComplete="email"
																				onChange={(e) => setFormDataEmail(e.target.value)}
																				value={formDataEmail}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																	<div className="sm:col-span-1">
																		<label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
																			First Name
																		</label>
																		<div className="mt-2">
																			<input
																				id="first_name"
																				name="first_name"
																				type="text"
																				autoComplete="first_name"
																				onChange={(e) => setFormDataFirstName(e.target.value)}
																				value={formDataFirstName}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																	<div className="sm:col-span-1">
																		<label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
																			Last Name
																		</label>
																		<div className="mt-2">
																			<input
																				id="last_name"
																				name="last_name"
																				type="text"
																				autoComplete="last_name"
																				onChange={(e) => setFormDataLastName(e.target.value)}
																				value={formDataLastName}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																	<div className="sm:col-span-1">
																		<label htmlFor="phone_number" className="block text-sm font-medium leading-6 text-gray-900">
																			Phone number
																		</label>
																		<div className="mt-2">
																			<input
																				id="phone_number"
																				name="phone_number"
																				type="text"
																				autoComplete="phone_number"
																				onChange={(e) => setFormDataPhoneNumber(e.target.value)}
																				value={formDataPhoneNumber}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																</div>
															</div>

															<div className="border-b border-gray-900/10 pb-12">
																<div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

																	<div className="col-span-full">
																		<label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
																			Street address
																		</label>
																		<div className="mt-2">
																			<input
																				type="text"
																				name="street-address"
																				id="street-address"
																				autoComplete="street-address"
																				onChange={(e) => setFormDataStreetAddress(e.target.value)}
																				value={formDataStreetAddress}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																	<div className="sm:col-span-2 sm:col-start-1">
																		<label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
																			City
																		</label>
																		<div className="mt-2">
																			<input
																				type="text"
																				name="city"
																				id="city"
																				autoComplete="address-level2"
																				onChange={(e) => setFormDataCity(e.target.value)}
																				value={formDataCity}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																	<div className="sm:col-span-2">
																		<label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
																			State / Province
																		</label>
																		<div className="mt-2">
																			<input
																				type="text"
																				name="region"
																				id="region"
																				autoComplete="address-level1"
																				onChange={(e) => setFormDataStateProvince(e.target.value)}
																				value={formDataStateProvince}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>

																	<div className="sm:col-span-2">
																		<label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
																			ZIP / Postal code
																		</label>
																		<div className="mt-2">
																			<input
																				type="text"
																				name="postal-code"
																				id="postal-code"
																				autoComplete="postal-code"
																				onChange={(e) => setFormDataZipPostalCode(e.target.value)}
																				value={formDataZipPostalCode}
																				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div>
															<h2 className="text-base font-semibold leading-7 text-gray-900">Documentation</h2>
														</div>

														<div className="w-full" hidden={!filesLoading}>
															<div className="flex w-full justify-center">
																<svg aria-hidden="true" className="w-12 h-12 mr-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
																	<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
																</svg>
															</div>
														</div>
														<div hidden={filesLoading} className="mt-8 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-1">
														
															{files && files.length > 0 && files.map((item: any, index: number) => {
																return <div key={index} className="sm:col-span-2 border-b border-gray-900/10 pb-8 mt-2">
																	<div className="block font-medium leading-6 text-gray-900 text-xl underline">
																		{item.temp_type_name}
																	</div>
																	<TemplateFileUpload tempKey={item.temp_type_id} documentNumber={item.document_type_id} existingFile={item.filename} GetFilesData={getFilesData} userID={formDataID} />
																</div>
															})}

														</div>

<div className="mt-4">
															<label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">
																Notes
															</label>
															<div className="mt-2">
																<textarea
																	id="notes"
																	name="notes"
																	rows={4}
																	autoComplete="notes"
																	value={formDataNotes}
																	onChange={(e) => {
																		setFormDataNotes(e.target.value)
																		formDataValidation()
																	}}
																	className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
																/>
															</div>
														</div>


														<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
															<button
																type="button"
																// disabled={!validName}
																className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700 sm:col-start-2"
																onClick={() => { setApproveConfirmOpen(true) }}
															>
																Approve
															</button>
															<button
																type="button"
																className="mt-3 inline-flex w-full justify-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700 sm:mt-0"
																onClick={() => { setRejectConfirmOpen(true) }}
															>
																Reject
															</button>
														</div>
														<div>
															<button
																id="role"
																type="button"
																disabled={true}
																className="block w-full bg-red-600 rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 outline-none"
																onClick={submitDelete}
															>
																Delete (Disabled)
															</button>
														</div>
													</div>
												</div>
											</div>
											<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
												<button
													type="submit"
													// disabled={!validName}
													className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
												>
													Update
												</button>
												<button
													type="button"
													className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0 outline-none hove:outline-none focus:outline-none"
													onClick={() => setEditModalOpen(false)}
												// ref={cancelButtonRef}
												>
													Cancel
												</button>
											</div>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<Transition.Root show={approveConfirmOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setApproveConfirmOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
											<CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
										</div>
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
												Approve Temp
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Are you sure you want to Approve this temp?
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
											onClick={submitApprove}
										>
											Approve
										</button>
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
											onClick={() => setApproveConfirmOpen(false)}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<Transition.Root show={rejectConfirmOpen} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setRejectConfirmOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div>
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
												Reject Temp
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Are you sure you want to Reject this temp?
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
											onClick={submitReject}
										>
											Reject
										</button>
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
											onClick={() => setRejectConfirmOpen(false)}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

		</>
	)
}